import { Controller } from "stimulus";
import APIClient from "lib/APIClient";
import editIconPath from "../../assets/images/edit.svg"

export default class extends Controller {
  static targets = ["editable"];
  static values = { 
    name: String, id: Number, authToken: String
  }
  
  editName(event) {
    event.preventDefault();
    let editor = document.createElement("input");
    let editIcon = document.createElement("img");
    
    Object.assign(editIcon, {
      src: editIconPath
    })
    editor.defaultValue = this.nameValue
    
    let style = window.getComputedStyle(event.target);
    editor.style.cssText = style.cssText

    const renameProject = () => {
      this.editableTarget.innerText = editor.value
      APIClient.renameProject(this.authTokenValue, this.idValue, this.editableTarget.innerText).then(() => window.location.reload())

      this.editableTarget.classList.remove("editable--hidden");
      this.element.insertAdjacentElement('beforeend', editIcon)
      editor.remove();
    }

    editor.addEventListener('keydown', function(event) {
      const key = event.key;
      if (key === 'Enter'
        || key === 'Escape') {
          renameProject();
      }
    }.bind(this));

    editor.addEventListener('blur', function(event) {
      renameProject();
    }.bind(this));
    
    let selection = window.getSelection();
    editor.setSelectionRange(selection.anchorOffset, selection.anchorOffset)
    
    this.editableTarget.insertAdjacentElement('afterend', editor)
    this.editableTarget.classList.add("editable--hidden")
  }
};
