import { Controller } from "stimulus";
import APIClient from "lib/APIClient";

export default class extends Controller {
  static targets = ["candidates", "toggle", "saveButton"];
  static values = {
    authToken: String,
    projectId: Number,
    searchId: Number
  };

  onToggleClick() {
    this.toggleCandidates(this.toggleTarget.checked);

    this.toggleSaveButton();
  };

  onSaveClick() {
    if (this.selectedCandidateIds().length > 0) {
      this.saveSelected();
    };
  };

  onCandidateSelect() {
    this.toggleSaveButton();
  };

  toggleCandidates(flag) {
    this.candidatesTargets.forEach((candidateTarget) => {
      candidateTarget.checked = flag;
    });
  };

  toggleSaveButton() {
    if (this.selectedCandidateIds().length > 0) {
      this.saveButtonTarget.classList.remove("button--disabled");
    } else {
      this.saveButtonTarget.classList.add("button--disabled");
    };
  };

  async saveSelected() {
    await APIClient.bulkSaveToProject(this.authTokenValue, this.selectedCandidateIds(), this.projectIdValue, this.searchIdValue);

    this.dispatchBulkSavedEvent();

    this.untoggleAll();
  };

  selectedCandidateIds() {
    return this.candidatesTargets.filter((candidateTarget) => {
      return candidateTarget.checked;
    }).map(candidateTarget => candidateTarget.dataset["candidateId"]);
  };

  dispatchBulkSavedEvent() {
    const event = new CustomEvent("bulkSaved", { detail: this.selectedCandidateIds() });

    window.dispatchEvent(event);
  };

  untoggleAll() {
    this.toggleTarget.checked = false;
    this.toggleCandidates(false);
  };
};