import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { candidateId: String }

  connect() {
    this.element.addEventListener('mouseup', this.handleMouseUp.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('mouseup', this.handleMouseUp.bind(this))
  }

  handleMouseUp(event) {
    const selectedText = window.getSelection().toString().trim()
    console.log(selectedText)
    if (selectedText) {
      this.showReasonForm(selectedText)
    }
  }

  showReasonForm(selectedText) {
    if (!this.reasonFormContainer) {
      this.reasonFormContainer = document.createElement('div')
      this.reasonFormContainer.className = 'layout-column layout-column--emphasized'
      this.element.appendChild(this.reasonFormContainer)
    }

    this.reasonFormContainer.innerHTML = ''

    const candidateIdP = document.createElement('p')
    candidateIdP.textContent = `Candidate ID: ${this.candidateIdValue}`

    const highlightedTextP = document.createElement('p')
    highlightedTextP.textContent = `Highlighted text: ${selectedText}`

    const form = document.createElement('form')
    form.addEventListener('submit', this.handleReasonSubmit.bind(this))

    const input = document.createElement('input')
    input.type = 'text'
    input.name = 'reason'

    const submit = document.createElement('input')
    submit.type = 'submit'

    form.appendChild(input)
    form.appendChild(submit)

    this.selectedText = selectedText
    this.inputField = input

    this.reasonFormContainer.appendChild(candidateIdP)
    this.reasonFormContainer.appendChild(highlightedTextP)
    this.reasonFormContainer.appendChild(form)
  }

  handleReasonSubmit(event) {
    event.preventDefault()
    const reason = this.inputField.value

    if (!window.refinements) {
      window.refinements = []
    }

    window.refinements.push({
      candidate_id: this.candidateIdValue,
      highlight: this.selectedText,
      reason: reason
    })

    const refinementsInput = document.getElementById('refinements_json')
    if (refinementsInput) {
      refinementsInput.value = JSON.stringify(window.refinements)
    }

    document.getElementById('search-action').click()

    this.reasonFormContainer.innerHTML = ''
    this.selectedText = ''
    this.inputField = null
  }
}
