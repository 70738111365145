// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import React from 'react';
import { createRoot } from 'react-dom/client';
import AddCandidateToSequenceModal from './components/AddCandidateToSequenceModal';

Rails.start()
ActiveStorage.start()

import "controllers"
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

window.init = function(...args) {
  const event = document.createEvent("Events");

  event.initEvent("google-maps-ready", true, true);

  event.args = args;

  window.dispatchEvent(event);
};

import TimeMe from "timeme.js";

import APIClient from "lib/APIClient";

window.APIClient = APIClient;

if (!window.location.href.includes("/company/dashboard")) {
  TimeMe.initialize({
    idleTimeoutInSeconds: 300
  });

  TimeMe.callWhenUserLeaves(function() {
    const authToken = document.getElementById("app-config").dataset.authToken;

    const pathArray = window.location.pathname.split('/');
    const projectIndex = pathArray.indexOf('projects');
    let projectId = null;
    if (projectIndex !== -1 && pathArray.length > projectIndex + 1) {
        projectId = pathArray[projectIndex + 1];
    }

    if (authToken) {
      APIClient.trackAppSession(authToken, TimeMe.getTimeOnCurrentPageInSeconds(), projectId);
    };

    TimeMe.resetAllRecordedPageTimes();
  });

  // window.addEventListener("beforeunload", function(event) {
  //   const authToken = document.getElementById("app-config").dataset.authToken;

  //   const pathArray = window.location.pathname.split('/');
  //   const projectIndex = pathArray.indexOf('projects');
  //   let projectId = null;
  //   if (projectIndex !== -1 && pathArray.length > projectIndex + 1) {
  //       projectId = pathArray[projectIndex + 1];
  //   }

  //   if (authToken) {
  //     APIClient.trackAppSession(authToken, TimeMe.getTimeOnCurrentPageInSeconds(), projectId);
  //   };
  // });
};

let root = null;

function openAddCandidateToSequenceModal(event, candidateId, authToken) {
  event.stopPropagation();

  const modalContainer = document.getElementById('addCandidateToSequenceModal');
  if (!root) {
    root = createRoot(modalContainer);
  }

  root.render(
    <AddCandidateToSequenceModal isOpen={true} onRequestClose={closeModal} candidateId={candidateId} authToken={authToken} />
  );
}

function closeModal() {
  const modalContainer = document.getElementById('addCandidateToSequenceModal');
  if (root) {
    root.render(
      <AddCandidateToSequenceModal isOpen={false} onRequestClose={closeModal} candidateId={null} />
    );
  }
}

window.openAddCandidateToSequenceModal = openAddCandidateToSequenceModal;
