import consumer from "./consumer";

function getCurrentPageProjectId() {
  const regex = /\/projects\/(\d+)\//;
  const match = window.location.pathname.match(regex);
  return match && match[1];
}

consumer.subscriptions.create("JobDescriptionChannel", {
  connected() {
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.status === 'job-description-upload-started') {
      const currentPageProjectId = getCurrentPageProjectId();

      if (currentPageProjectId ===  data.project_id.toString()) {
        const keywordsLoadingContainer = document.getElementById('keywordsLoadingContainer');
        if (keywordsLoadingContainer) {
          keywordsLoadingContainer.style.display = 'flex';
        }

        const analysisCompleteContainer = document.getElementById('analysisCompleteContainer');
        if (analysisCompleteContainer) {
          analysisCompleteContainer.style.display = 'none';
        }
        
        const jobDescriptionInputContainer = document.getElementById('jobDescriptionInputContainer');
        if (jobDescriptionInputContainer) {
          jobDescriptionInputContainer.style.display = 'none';
        }
      }
    } else if (data.status === 'job-description-upload-error') {
      const currentPageProjectId = getCurrentPageProjectId();

      if (currentPageProjectId ===  data.project_id.toString()) {
        const keywordsLoadingContainer = document.getElementById('keywordsLoadingContainer');
        if (keywordsLoadingContainer) {
          keywordsLoadingContainer.style.display = 'none';
        }
      }
    } else if (data.status === 'job-description-analysis-completed') {
      const currentPageProjectId = getCurrentPageProjectId();

      if (currentPageProjectId ===  data.project_id.toString()) {
        const keywordsLoadingContainer = document.getElementById('keywordsLoadingContainer');
        if (keywordsLoadingContainer) {
          keywordsLoadingContainer.style.display = 'none';
        }

        const analysisCompleteContainer = document.getElementById('analysisCompleteContainer');
        if (analysisCompleteContainer) {
          analysisCompleteContainer.style.display = 'flex';
        }
      }

      const flashBanner = document.getElementById('flash-notice');
      flashBanner.style.display = 'block';
      flashBanner.innerHTML = `Job description analysis for the "${data.project_name}" project is complete!`;

      if (data.route_to) {
        flashBanner.innerHTML += ` <a href="#" id="viewProjectUpdates" style="font-weight: bold; color: white; text-decoration: underline;">View search results</a>`;
        const newLink = document.getElementById("viewProjectUpdates");
        
        newLink.addEventListener('click', () => {
          // this is the only way we can get react components to render on page load.
          window.location.href = data.route_to.toString();
        });
      };
    }
  }
});
