import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["row"];

  highlight(event) {
    event.preventDefault();    
    this.rowTarget.addEventListener('mouseover', function(event) {
      const link = event.currentTarget.querySelector('.project-name-link');
      if (!link) return;
      link.classList.add("active")
    }.bind(this))
  
  }

  fade(event) {
    event.preventDefault();    
    this.rowTarget.addEventListener('mouseout', function(event) {
      const link = event.currentTarget.querySelector('.project-name-link');
      if (!link) return;

      link.classList.remove("active")
    }.bind(this))
  }
};
