import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("liveFormController.apply", () => {
      this.apply();
    });
  };

  apply() {
    this.element.requestSubmit();
  };

  clearFilter(event) {
    const $checkboxes = event.currentTarget.parentElement.parentElement.querySelectorAll("input[type=\"checkbox\"]");
    const $textInputs = event.currentTarget.parentElement.parentElement.querySelectorAll("input[type=\"text\"]");

    $checkboxes.forEach($checkbox => $checkbox.checked = false);
    $textInputs.forEach($textInput => $textInput.value = "");

    this.apply();
  };

  toggleAllKeywords(event) {
    const isChecked = event.currentTarget.checked;

    const container = document.getElementById("hidden_keywords_container");
    container.innerHTML = '';
  
    if (isChecked) {
      this.addAllKeywordsToHiddenInputs();
    }
  
    this.apply();
  }

  addAllKeywordsToHiddenInputs() {
    const container = document.getElementById("hidden_keywords_container");
  
    const allKeywords = this.getAllAvailableKeywords();
  
    allKeywords.forEach(keyword => {
      const newInput = document.createElement("input");
      newInput.type = "hidden";
      newInput.name = "job_description_keywords[]";
      newInput.value = keyword;
      container.appendChild(newInput);
    });
  }
  
  getAllAvailableKeywords() {
    const container = document.getElementById("hidden_keywords_container");
    const keywords = JSON.parse(container.dataset.keywords);
    return keywords;
  }
  
  toggleKeyword(event) {
    event.preventDefault();
    const container = document.getElementById("hidden_keywords_container");
    
    const keyword = event.currentTarget.dataset.keyword;
    const urlParams = new URLSearchParams(window.location.search);
    let keywordsArray = urlParams.getAll('job_description_keywords[]');
    keywordsArray.includes(keyword) ? keywordsArray = keywordsArray.filter(k => k !== keyword) : keywordsArray.push(keyword);
    
    keywordsArray.map((existingKeyword) => {      
      const newInput = document.createElement("input");
      newInput.type = "hidden";
      newInput.name = "job_description_keywords[]";
      newInput.value = existingKeyword;
      container.appendChild(newInput);
    })

    this.apply();
  }

  clearAllFilters() {
    const $checkboxes = document.querySelectorAll("input[type=\"checkbox\"]");
    const $textInputs = document.querySelectorAll("input[type=\"text\"]");
    const $hiddenInputs = document.querySelectorAll("input[type=\"hidden\"]");

    $checkboxes.forEach($checkbox => $checkbox.checked = false);
    $textInputs.forEach(($textInput) => {
      if ($textInput.id !== "query") {
        $textInput.value = null;
      };
    });
    $hiddenInputs.forEach($hiddenInput => $hiddenInput.remove());

    this.apply();
  };
};
